<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>

        <v-row justify="start mt-10">
            <v-col
                    cols="auto"
                    v-for="title in titles"
                    v-bind:key="title.link"
                    class="viewCard"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <router-link :to="title.link">
                            <v-card
                                    :elevation="10"
                                    class="blue darken-1 text-center"
                                    height="100"
                                    width="200"
                            >
                                <v-list-item three-line>
                                    <v-list-item-content class="white--text">
                                        <v-row align="center" justify="center">
                                            <span>{{title.name}}</span>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row align="center" justify="center">
                                            <span>100</span>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </router-link>
                    </template>
                </v-tooltip>
            </v-col>
        </v-row>


    </v-content>
</template>

<script>

    export default {
        name: "Dashboard",
        watch: {},
        components: {},
        data() {
            return {
                titles: [
                    {
                        name: "Exchange Rate",
                        link: "/Income-GenerationService"
                    },
                    {
                        name: "Sector Setup",
                        link: "/Income-GenerationSectorSetup"
                    }
                ],
                items: [
                    {
                        text: "Settings",
                        disabled: true,
                        to: "/Hotel",
                        exact: true
                    }
                ],
                branchInformation: []

            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            formData() {

            },
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>